import React from "react";
import Layout from "../components/layout/index";
import { Grid, useMediaQuery } from "@material-ui/core";
import { graphql, Link } from "gatsby";
// import ReactMarkdown from "react-markdown"
// import { STRAPI_API_URL } from "../utils/constants"
import { PICTURE_STRAPI_API_URL } from "../utils/constants";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import parse, { domToReact } from "html-react-parser";
export default function Terms({ data }) {
  const { strapiTerms } = data;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // function Image(props) {
  //   return <img {...props} style={{ maxWidth: "100%" }} alt="thumbnail" />
  // }

  const getId = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "error";
    }
  };
  const seoData = {
    title: data?.strapiTerms?.seo_title,
    description: data?.strapiTerms?.seo_description,
    keyword: data?.strapiTerms?.seo_tags,
    url: "/terms"
  };
  return (
    <Layout page={"terms"} seoData={seoData}>
      <Grid
        container
        justify="center"
        style={{ marginTop: isMobile ? "15%" : "5%" }}
      >
        <h1
          className={classes.heading}
          style={{ fontSize: isMobile && "24px" }}
        >
          {strapiTerms?.title}
        </h1>
      </Grid>

      <Grid
        item
        xs={12}
        className={classes.description}
        style={{
          padding: isMobile ? "0px 5px" : "20px 30px",
          fontSize: isMobile && "18px",
        }}
      >
        {/* <ReactMarkdown
          source={strapiTerms?.termsAndConditions}
          renderers={{ image: Image }}
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${STRAPI_API_URL}${uri}`
          }
        /> */}

        <div className="ck-content">
          {strapiTerms?.termsAndConditions &&
            parse(strapiTerms?.termsAndConditions, {
              replace: (domNode) => {
                if (domNode.name && domNode.name === "img") {
                  return React.createElement("img", {
                    src: `${PICTURE_STRAPI_API_URL}${domNode?.attribs?.src}`,
                    alt: `${domNode?.attribs?.alt}`,
                    style: { paddingLeft: "5%" },
                  });
                }
                if (
                  domNode.name &&
                  domNode.name === "a" &&
                  !domNode?.attribs?.href?.includes("mailto:")
                ) {
                  return (
                    <Link
                      to={
                        domNode?.attribs?.href?.includes("https://")
                          ? domNode?.attribs?.href
                          : `https://${domNode?.attribs?.href}`
                      }
                      target="_blank"
                    >
                      {domToReact(domNode?.children, domNode?.options)}
                    </Link>
                  );
                }
                if (domNode.name && domNode.name === "oembed") {
                  let videoId = getId(domNode?.attribs?.url);

                  return (
                    <Grid
                      container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: isMobile ? "200px" : "500px",
                      }}
                    >
                      <iframe
                        width="90%"
                        height={"100%"}
                        title="title"
                        src={`//www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </Grid>
                  );
                }
              },
            })}
        </div>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    // fontWeight: 100,
    fontSize: "22px",
    lineHeight: "151.69%",
    letterSpacing: "0.045em",
    textAlign: "justify",
    color: "#3F3F3F",
    padding: "14px 35px",
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontSize: "32px",
  },
}));

export const query = graphql`
  {
    strapiTerms {
      title
      termsAndConditions
      seo_title
      seo_tags
      seo_description
    }
  }
`;
